




















import Vue from 'vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import CloseButton from '@/components/DetailedViewPopup/ActionButton.vue';

export default Vue.extend({
  name: 'DetailedViewBase',
  components: {
    LoadingIndicator,
    CloseButton,
  },
  props: {
    opened: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    isLarge: { type: Boolean, default: false },
    isMedium: { type: Boolean, default: false },
  },
  data() {
    return {
      closeIcon: '/images/detailed-view-close.svg',
    };
  },
});
