














import Vue from 'vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';

export default Vue.extend({
  name: 'Checkbox',
  components: {
    LoadingIndicator,
  },
  model: {
    prop: 'isActive',
    event: 'change',
  },
  props: {
    isActive: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
      active: this.isActive,
    };
  },
  watch: {
    isActive() {
      this.active = this.isActive;
    },
  },
  methods: {
    setLoading(value: boolean) {
      this.loading = value;
    },
    change() {
      this.$emit('change', !this.active);
    },
  },
});
