






















import Vue from 'vue';
import Menu from '@/model/menu';

export default Vue.extend({
  name: 'Navigation',
  model: {
    prop: 'activeComponent',
    event: 'change',
  },
  props: {
    activeComponent: {
      validator: (value: string) =>
        [
          'Tasks',
          'Attachments',
          'Comments',
          'Changelog',
          'DocumentStoreDocuments',
          'DocumentStoreComments',
          'DocumentStoreChangelog',
          'DocumentStoreBasicInfiormations',
          'OperationalUnitDetails',
          'OperationalUnitDocuments',
          'PlaceDetails',
          'PlaceSubUnits',
          'PlaceDocuments',
          'PersonDetails',
          'PersonEducations',
          'PersonMedicalExaminations',
          'PersonDocuments',
          'ToolDetails',
          'ToolDocuments',
        ].includes(value),
    },
    isEditing: { type: Boolean, default: false },
    menus: Array as () => Menu[],
  },
  methods: {
    changeActiveComponent(componentName: string) {
      if (!this.isEditing) {
        this.$emit('change', componentName);
      }
    },
  },
});
